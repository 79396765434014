.banner {
  max-height: 400px;
  height: 100%;
  border-radius: 8px; }
  @media (max-width: 600px) {
    .banner {
      height: 300px; } }
  @media (max-width: 500px) {
    .banner {
      border-radius: 3px; } }
  .banner .slide {
    display: flex; }
    .banner .slide .column-left {
      width: 100%;
      position: relative; }
      .banner .slide .column-left img {
        max-width: 670px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
  .banner .swiper_btn div {
    background: #FFFFFF;
    width: 66px;
    height: 66px; }
  .banner .swiper_btn .swiper-button-next {
    position: absolute;
    right: 0;
    border-radius: 50px 0px 0px 50px; }
  .banner .swiper_btn .swiper-button-prev {
    position: absolute;
    left: 0;
    border-radius: 0px 50px 50px 0px; }
  .banner .swiper_btn .swiper-button-prev::after,
  .banner .swiper_btn .swiper-button-next:after {
    font-size: 18px;
    color: #737988; }
  .banner .swiper_btn .swiper-button-prev::after {
    margin-right: 15px; }
  .banner .swiper_btn .swiper-button-next:after {
    margin-left: 15px; }
  .banner .swiper-pagination .swiper-pagination-bullet::after {
    background-color: #fff;
    border: none;
    width: 7px;
    height: 7px; }
  .banner .swiper-pagination .swiper-pagination-bullet-active::after {
    background: #FFA600;
    width: 10px;
    height: 10px; }

.column-right {
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 1; }
  .column-right .banner_title {
    position: relative;
    left: -5%;
    font-weight: bold;
    font-size: 45px;
    color: #737988; }
    @media (max-width: 500px) {
      .column-right .banner_title {
        display: none; } }
  .column-right .banner__btn {
    margin-top: 30px;
    position: relative;
    width: 183px; }
    @media (max-width: 500px) {
      .column-right .banner__btn {
        display: none; } }
    .column-right .banner__btn a {
      padding: 15px 40px; }
  .column-right img {
    position: relative;
    top: 1%;
    left: -30%;
    opacity: 0.8; }

.banner_title_mob {
  display: none;
  justify-content: center;
  flex-wrap: wrap; }
  @media (max-width: 500px) {
    .banner_title_mob {
      display: flex; } }
  .banner_title_mob div {
    font-weight: bold;
    font-size: 25px;
    color: #737988;
    margin-right: 10px; }

.chat {
  position: fixed;
  right: 1px;
  bottom: 10%;
  z-index: 3; }
  .chat:hover img {
    transition: 0.3s all;
    transform: scale(1.2); }

.brands {
  margin: 40px 0; }
  .brands a {
    height: auto; }
    .brands a img {
      width: 100%;
      height: 100%; }

.about_us {
  width: auto;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1200px) {
    .about_us {
      flex-direction: column;
      justify-content: center; } }
  .about_us__img {
    width: 49%;
    min-height: 300px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 20px; }
    @media (max-width: 1200px) {
      .about_us__img {
        width: 100%; } }
    .about_us__img .left {
      width: 50%; }
      .about_us__img .left .logo {
        margin-top: 20px;
        width: 200px;
        text-align: center;
        position: relative;
        left: 10%; }
      .about_us__img .left .info {
        position: relative;
        width: 100%;
        left: 10%;
        color: white; }
        @media (max-width: 500px) {
          .about_us__img .left .info {
            left: 3%; } }
        .about_us__img .left .info__address {
          margin-top: 50px;
          font-size: 15px;
          z-index: 10; }
          @media (max-width: 500px) {
            .about_us__img .left .info__address {
              font-size: 12px;
              margin-top: 20px; } }
        .about_us__img .left .info__number {
          font-size: 24px;
          margin-bottom: 30px;
          z-index: 10; }
          @media (max-width: 500px) {
            .about_us__img .left .info__number {
              font-size: 16px; } }
          @media (max-width: 350px) {
            .about_us__img .left .info__number {
              font-size: 14px; } }
          .about_us__img .left .info__number .number-link {
            white-space: nowrap; }
        .about_us__img .left .info a {
          color: white; }
    .about_us__img .right {
      position: absolute;
      right: 0;
      bottom: 0;
      max-width: 400px;
      height: 100%;
      display: flex;
      align-items: flex-end; }
      @media (max-width: 600px) {
        .about_us__img .right {
          max-width: 300px; } }
      @media (max-width: 400px) {
        .about_us__img .right {
          max-width: 250px; } }
      .about_us__img .right img {
        width: 100%; }
  .about_us__text {
    width: 45%;
    padding: 50px;
    font-size: 20px;
    color: #737988;
    border-radius: 8px; }
    @media (max-width: 1200px) {
      .about_us__text {
        width: 100%; } }
    @media (max-width: 500px) {
      .about_us__text {
        padding: 20px; } }

.reviews-section_container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }

.reviews .reviews_block {
  width: 30%; }
  @media (max-width: 992px) {
    .reviews .reviews_block {
      width: 64%; } }
  @media (min-width: 992px) {
    .reviews .reviews_block {
      margin-left: 0; } }
  @media (max-width: 700px) {
    .reviews .reviews_block {
      width: 100%; } }
  .reviews .reviews_block__social {
    width: 30%;
    height: auto;
    padding: 30px;
    border-radius: 8px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px; }
    @media (max-width: 992px) {
      .reviews .reviews_block__social {
        width: 70%; } }
    @media (max-width: 600px) {
      .reviews .reviews_block__social {
        width: 100%; } }
    .reviews .reviews_block__social .subscription {
      margin-top: 20px; }
      .reviews .reviews_block__social .subscription img {
        transition: 0.3s; }
        .reviews .reviews_block__social .subscription img:hover {
          transform: scale(1.1); }
    .reviews .reviews_block__social .social__title {
      font-size: 24px;
      font-weight: 800; }
    .reviews .reviews_block__social .social__text {
      font-size: 14px;
      opacity: 0.6; }

.reviews .social_block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px; }
  @media (max-width: 992px) {
    .reviews .social_block {
      justify-content: center; } }

@media (max-width: 500px) {
  .top,
  .about_us {
    padding: 0; } }

.banner__btn_mob {
  margin-top: 20px;
  display: none;
  height: 49px;
  text-align: center; }
  @media (max-width: 500px) {
    .banner__btn_mob {
      display: block; } }
  .banner__btn_mob a {
    padding: 15px 40px; }
